<template>
  <div>
    <div class="card-marketing p-2" :style="`background: ${colorCard}`">
      <b-row>
        <b-col class="text-white d-flex align-items-start col-12">
          <div>
            <span class="text-information">
              {{ textInfo === "influencers" ? `${$t(`creator.cardTitle.${textInfo}`)} @${getTextAfterAt(mention)}` : $t(`creator.cardTitle.${textInfo}`) }}
            </span>
          </div>
        </b-col>
        <b-col class="text-white d-flex col-12 align-items-end">
          <b-avatar-group>
            <b-avatar v-for="(image, index) in images" :key="`image-${index}`" :src="image"></b-avatar>
          </b-avatar-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  
<script>
import { BRow, BCol, BAvatarGroup, BAvatar } from "bootstrap-vue";

export default {
  name: "AnalyzerCardSecondary",
  components: {
    BRow,
    BCol,
    BAvatarGroup,
    BAvatar
  },
  props: {
    textInfo: {
      type: String,
      default: ''
    },
    mention: {
      type: String,
      default: ''
    },
    colorCard: {
      type: String,
      default: '#039BE5'
    },
    images: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {},
    }
  },
  methods: {
    getTextAfterAt(mention) {
      const atIndex = mention.indexOf('@');
      if (atIndex !== -1) {
        return mention.substring(atIndex + 1);
      }
      return '';
    }
  },
};
</script>
  
<style scoped>
.text-information {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}
.card-marketing {
  min-height: 160px;
  border-radius: 1.5em;
  display: flex;
  overflow: hidden;
}
</style>